.card {
    position: relative;
    padding: 32px;
    padding-bottom: 29px;
    margin-bottom: 9px;
    border-radius: 24px;
    border: 1px solid #E2E8FF;
    background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(118, 146, 255, 0.06) 80.55%, rgba(122, 150, 255, 0.02) 100%), radial-gradient(70.71% 70.71% at 50% 50%, rgba(226, 232, 255, 0.00) 33.78%, rgba(226, 232, 255, 0.06) 100%, rgba(226, 232, 255, 0.34) 100%), rgba(226, 232, 255, 0.12);
    box-shadow: 0px -14px 42px -12px rgba(226, 232, 255, 0.50) inset, 0px 0px 12px 0.5px rgba(255, 255, 255, 0.80) inset, 0px -14px 42px -12px rgba(226, 232, 255, 0.50) inset, 0px 0px 12px 0.5px rgba(255, 255, 255, 0.80) inset, 0px 0px 30px 5px rgba(255, 255, 255, 0.50) inset, 0px 5px 40px 0px rgba(113, 144, 175, 0.10);
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }


    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 24px;
        background-color: rgba(0,0,0,0.1);
        opacity: 0;
        transition: 0.3s opacity;
    }

    &:hover {
        &:before {
            opacity: 0.3;
        }
    }
    
    &.selected {
        border: 1.5px solid #E2E8FF;
        background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(118, 146, 255, 0.06) 80.55%, rgba(122, 150, 255, 0.02) 100%), radial-gradient(70.71% 70.71% at 50% 50%, rgba(226, 232, 255, 0.00) 33.78%, rgba(226, 232, 255, 0.06) 100%, rgba(226, 232, 255, 0.34) 100%), linear-gradient(0deg, rgba(226, 232, 255, 0.70) 0%, rgba(226, 232, 255, 0.70) 100%), #F1F2F5;
    }

    h5 {
        color: @black;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
    }
    p {
        color: #5C5C5C;
        font-size: 16px;
        font-weight: 500;
    }
}

@import '/Users/klikdokter/Personal/tekuton-id/tekuton-compro-web/src/styles/variables.less';