.home-container {
    .section {
        color: #5C5C5C;
        padding: 135px 0;

        .label {
            font-size: 20px;
            font-weight: 500;
        }
        .title {
            font-size: 64px;
            font-weight: 300;
        }
        p {
            font-size: 16px;
            font-weight: 500;
        }

        @media only screen and (max-width: @screen-md) {
            .title {
                font-size: 48px;
            }
        }
    }

    .section-1 {
        min-height: 100vh;
		background-image: url('/images/home-bg-1.png');
        background-size: cover;
        padding-top: 86px;
        padding-bottom: 94px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .section-2 {
        padding: 38px 0;
        color: @white;
        background-color: #141414;
		background-image: url('/images/home-bg-2.png');
        background-repeat: no-repeat;
        background-position: left bottom;
        
        .image-container {
            position: relative;
            max-width: 388px;
            width: 100%;
            padding-top: 120%;
            margin: auto;
        }
    }

    .section-3 {
        padding-top: 66px;
        padding-bottom: 86px;
        background-color: @white;

        .image-container {
            position: relative;
            width: 100%;
            padding-top: 112%;
            border-radius: 24px;
            border: 1px solid #E2E8FF;
            background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(118, 146, 255, 0.06) 80.55%, rgba(122, 150, 255, 0.02) 100%), radial-gradient(70.71% 70.71% at 50% 50%, rgba(226, 232, 255, 0.00) 33.78%, rgba(226, 232, 255, 0.06) 100%, rgba(226, 232, 255, 0.34) 100%), rgba(226, 232, 255, 0.12);
        
            img {  
                border-radius: 24px;
            }
        }
    }

    .section-4 {
        padding-top: 69px;
        padding-bottom: 72px;
        background-color: #E1E1E1;

        .images-section {
            [class~=row] {
                row-gap: 20px;
            }
        }
    }

    .section-5 {
        padding: 86px 0;
        background-color: @white;
    }

    .section-6 {
        padding: 86px 0;
        background-color: @white;
    }

    .section-7 {
        padding: 0 0 86px;
        background-color: @white;

        .image-container {
            width: 100%;
            padding-top: 25%;
        }
    }
}

@import '/Users/klikdokter/Personal/tekuton-id/tekuton-compro-web/src/styles/variables.less';