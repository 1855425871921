.card {
    height: 100%;
    padding: 32px 32px 58px 32px;
    border-radius: 24px;
    border: 1px solid #E2E8FF;
    background: radial-gradient(70.71% 70.71% at 50% 50%, rgba(118, 146, 255, 0.06) 80.55%, rgba(122, 150, 255, 0.02) 100%), radial-gradient(70.71% 70.71% at 50% 50%, rgba(226, 232, 255, 0.00) 33.78%, rgba(226, 232, 255, 0.06) 100%, rgba(226, 232, 255, 0.34) 100%), linear-gradient(0deg, rgba(226, 232, 255, 0.12) 0%, rgba(226, 232, 255, 0.12) 100%), #F1F2F5;
    box-shadow: 0px -14px 42px -12px rgba(226, 232, 255, 0.50) inset, 0px 0px 12px 0.5px rgba(255, 255, 255, 0.80) inset, 0px -14px 42px -12px rgba(226, 232, 255, 0.50) inset, 0px 0px 12px 0.5px rgba(255, 255, 255, 0.80) inset, 0px 0px 30px 5px rgba(255, 255, 255, 0.50) inset, 0px 5px 40px 0px rgba(113, 144, 175, 0.10);

    .image {
        position: relative;
        height: 85px;
        width: 85px;
    }

    h5 {
        color: @black;
        font-size: 20px;
        font-weight: 500;
    }
    p {
        color: #5C5C5C;
        font-size: 16px;
        font-weight: 500;
    }
}

@import '/Users/klikdokter/Personal/tekuton-id/tekuton-compro-web/src/styles/variables.less';