
.card {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    cursor: pointer;

    &.type-1 {
        width: 100%;
        padding-top: 55.55%;
    }

    &.type-2 {
        width: 100%;
        padding-top: 117.10%;
    }

    img {  
        border-radius: 24px;
    }

    .card-mask {
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 24px;
        background: rgba(29, 29, 29, 0.80);
        padding: 23px 32px;
        transition: 0.3s opacity;
    
        .content {
            height: 69px;
            display: flex;
            flex-direction: column;

            .title {
                color: @white;
                font-size: 24px;
                font-weight: 700;
            }
        
            .description {
                color: #959595;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    &:hover {
        .card-mask {
            opacity: 1;
        }
    }
}

@import '/Users/klikdokter/Personal/tekuton-id/tekuton-compro-web/src/styles/variables.less';